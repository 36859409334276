import { createApi } from '@reduxjs/toolkit/query/react';
import { default as customBaseQuery } from './baseQuery';
export const api = createApi({
    baseQuery: customBaseQuery,
    tagTypes: [],
    endpoints: (build) => ({
        actionsExecute: build.mutation<
            ActionsExecuteApiResponse,
            ActionsExecuteApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Actions/execute`,
                method: 'POST',
                body: queryArg.activity
            })
        }),
        actionsList: build.query<ActionsListApiResponse, ActionsListApiArg>({
            query: () => ({ url: `/api/Actions/list` })
        }),
        alternateIdsMap: build.query<
            AlternateIdsMapApiResponse,
            AlternateIdsMapApiArg
        >({
            query: () => ({ url: `/api/AlternateIds/Map` })
        }),
        engSubsIndex: build.query<EngSubsIndexApiResponse, EngSubsIndexApiArg>({
            query: (queryArg) => ({
                url: `/api/EngSubs`,
                params: {
                    episodeId: queryArg.episodeId,
                    time: queryArg.time,
                    count: queryArg.count
                }
            })
        }),
        engSubsStylesOf: build.query<
            EngSubsStylesOfApiResponse,
            EngSubsStylesOfApiArg
        >({
            query: (queryArg) => ({
                url: `/api/EngSubs/StylesOf/${queryArg.showId}`
            })
        }),
        engSubsActiveStylesFor: build.query<
            EngSubsActiveStylesForApiResponse,
            EngSubsActiveStylesForApiArg
        >({
            query: (queryArg) => ({
                url: `/api/EngSubs/ActiveStylesFor/${queryArg.showId}`
            })
        }),
        engSubsShowIdOf: build.query<
            EngSubsShowIdOfApiResponse,
            EngSubsShowIdOfApiArg
        >({
            query: (queryArg) => ({
                url: `/api/EngSubs/ShowIdOf/${queryArg.episodeId}`
            })
        }),
        engSubsByStyleName: build.query<
            EngSubsByStyleNameApiResponse,
            EngSubsByStyleNameApiArg
        >({
            query: (queryArg) => ({
                url: `/api/EngSubs/ByStyleName`,
                params: {
                    showId: queryArg.showId,
                    styleName: queryArg.styleName,
                    Max: queryArg.max,
                    Skip: queryArg.skip
                }
            })
        }),
        episodeIndex: build.query<EpisodeIndexApiResponse, EpisodeIndexApiArg>({
            query: (queryArg) => ({
                url: `/api/Episode/${queryArg.episodeId}`,
                params: { analyzer: queryArg.analyzer }
            })
        }),
        imageIndex: build.query<ImageIndexApiResponse, ImageIndexApiArg>({
            query: (queryArg) => ({
                url: `/api/Image/${queryArg.episodeId}/${queryArg.time}`,
                params: { includeSubs: queryArg.includeSubs }
            })
        }),
        subsById: build.query<SubsByIdApiResponse, SubsByIdApiArg>({
            query: (queryArg) => ({
                url: `/api/Subs/ById/${queryArg.id}`,
                params: { analyzer: queryArg.analyzer }
            })
        }),
        userGetTodoistToken: build.query<
            UserGetTodoistTokenApiResponse,
            UserGetTodoistTokenApiArg
        >({
            query: () => ({ url: `/api/User/todoistToken` })
        }),
        userPutTodoistToken: build.mutation<
            UserPutTodoistTokenApiResponse,
            UserPutTodoistTokenApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/todoistToken`,
                method: 'PUT',
                body: queryArg.body
            })
        }),
        wordsRanked2: build.query<WordsRanked2ApiResponse, WordsRanked2ApiArg>({
            query: (queryArg) => ({
                url: `/api/Words/Ranked2`,
                params: {
                    analyzer: queryArg.analyzer,
                    Max: queryArg.max,
                    Skip: queryArg.skip,
                    skipKnown: queryArg.skipKnown
                }
            })
        }),
        wordsSearch: build.query<WordsSearchApiResponse, WordsSearchApiArg>({
            query: (queryArg) => ({
                url: `/api/Words/Search`,
                params: { query: queryArg.query }
            })
        }),
        wordsDefinition: build.query<
            WordsDefinitionApiResponse,
            WordsDefinitionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Words/Definition`,
                params: { wordId: queryArg.wordId }
            })
        }),
        wordsEpisodeRank: build.query<
            WordsEpisodeRankApiResponse,
            WordsEpisodeRankApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Words/EpisodeRank`,
                params: {
                    analyzer: queryArg.analyzer,
                    episodeId: queryArg.episodeId,
                    wordId: queryArg.wordId
                }
            })
        }),
        wordsUnknownRanks: build.query<
            WordsUnknownRanksApiResponse,
            WordsUnknownRanksApiArg
        >({
            query: () => ({ url: `/api/Words/UnknownRanks` })
        }),
        wordsEpisodeTotal: build.query<
            WordsEpisodeTotalApiResponse,
            WordsEpisodeTotalApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Words/EpisodeTotal`,
                params: {
                    analyzer: queryArg.analyzer,
                    episodeId: queryArg.episodeId
                }
            })
        }),
        wordsOccurrences: build.query<
            WordsOccurrencesApiResponse,
            WordsOccurrencesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Words/Occurrences`,
                params: { analyzer: queryArg.analyzer, wordId: queryArg.wordId }
            })
        }),
        wordsNotes: build.query<WordsNotesApiResponse, WordsNotesApiArg>({
            query: (queryArg) => ({
                url: `/api/Words/known/${queryArg.wordId}/Notes`
            })
        }),
        wordsKnown: build.query<WordsKnownApiResponse, WordsKnownApiArg>({
            query: () => ({ url: `/api/Words/Known` })
        }),
        wordsWithReadingsKnown: build.query<
            WordsWithReadingsKnownApiResponse,
            WordsWithReadingsKnownApiArg
        >({
            query: () => ({ url: `/api/Words/WithReadingsKnown` })
        })
    })
});
export type ActionsExecuteApiResponse = unknown;
export type ActionsExecuteApiArg = {
    activity: Activity;
};
export type ActionsListApiResponse = /** status 200  */ ActivityExecution[];
export type ActionsListApiArg = {};
export type AlternateIdsMapApiResponse = /** status 200  */ {
    [key: string]: string;
};
export type AlternateIdsMapApiArg = {};
export type EngSubsIndexApiResponse = /** status 200  */ EngSubsResponse;
export type EngSubsIndexApiArg = {
    episodeId: string;
    time: number;
    count?: number;
};
export type EngSubsStylesOfApiResponse = /** status 200  */ StylesOfResponse;
export type EngSubsStylesOfApiArg = {
    showId: number;
};
export type EngSubsActiveStylesForApiResponse = /** status 200  */ string[];
export type EngSubsActiveStylesForApiArg = {
    showId: number;
};
export type EngSubsShowIdOfApiResponse = /** status 200  */ number | null;
export type EngSubsShowIdOfApiArg = {
    episodeId: string;
};
export type EngSubsByStyleNameApiResponse = /** status 200  */ EngSubsResponse;
export type EngSubsByStyleNameApiArg = {
    showId: number;
    styleName: string;
    max: number;
    skip: number;
};
export type EpisodeIndexApiResponse = /** status 200  */ Episode;
export type EpisodeIndexApiArg = {
    analyzer: Analyzer;
    episodeId: string;
};
export type ImageIndexApiResponse = unknown;
export type ImageIndexApiArg = {
    episodeId: number;
    time: number;
    includeSubs?: boolean;
};
export type SubsByIdApiResponse = /** status 200  */ WordOccurrence;
export type SubsByIdApiArg = {
    analyzer: Analyzer;
    id: string;
};
export type UserGetTodoistTokenApiResponse = /** status 200  */ string;
export type UserGetTodoistTokenApiArg = {};
export type UserPutTodoistTokenApiResponse = /** status 200  */ string;
export type UserPutTodoistTokenApiArg = {
    body: string;
};
export type WordsRanked2ApiResponse = /** status 200  */ WordRankInfo[];
export type WordsRanked2ApiArg = {
    analyzer: Analyzer;
    max: number;
    skip: number;
    skipKnown?: boolean;
};
export type WordsSearchApiResponse = /** status 200  */ number[];
export type WordsSearchApiArg = {
    query: string;
};
export type WordsDefinitionApiResponse = /** status 200  */ WordDefinition[];
export type WordsDefinitionApiArg = {
    wordId: number[];
};
export type WordsEpisodeRankApiResponse = /** status 200  */ WordRank[];
export type WordsEpisodeRankApiArg = {
    analyzer: Analyzer;
    episodeId: string;
    wordId: number[];
};
export type WordsUnknownRanksApiResponse = /** status 200  */ {
    [key: string]: number | null;
};
export type WordsUnknownRanksApiArg = {};
export type WordsEpisodeTotalApiResponse = unknown;
export type WordsEpisodeTotalApiArg = {
    analyzer: Analyzer;
    episodeId: number;
};
export type WordsOccurrencesApiResponse = /** status 200  */ WordOccurrences;
export type WordsOccurrencesApiArg = {
    analyzer: Analyzer;
    wordId: number;
};
export type WordsNotesApiResponse = /** status 200  */ NoteInfo[];
export type WordsNotesApiArg = {
    wordId: number;
};
export type WordsKnownApiResponse = /** status 200  */ {
    [key: string]: boolean;
};
export type WordsKnownApiArg = {};
export type WordsWithReadingsKnownApiResponse = /** status 200  */ number[];
export type WordsWithReadingsKnownApiArg = {};
export type Activity = {
    activityType: string;
};
export type ObjectId = {
    Timestamp: number;
    Machine: number;
    Pid: number;
    Increment: number;
    CreationTime: string;
};
export type ActivityExecution = {
    id: ObjectId;
    activity: Activity;
};
export type Sentence = {
    id: string;
    time: number;
    episodeId: string;
    text: string[];
    episodeTitle?: string | null;
};
export type EngSubsResponse = {
    dialog: Sentence[];
};
export type AggregateSortByCountResultOfString = {
    count: number;
    id: string;
};
export type StylesOfResponse = {
    showId: number;
    allStyles: AggregateSortByCountResultOfString[];
    enabledStyles: string[];
};
export type Entry = {
    time: number;
    dialogId: string;
};
export type Episode = {
    id: string;
    title: string;
    entries: Entry[];
};
export type Analyzer = 'Kuromoji' | 'SudachiA' | 'SudachiB' | 'SudachiC';
export type WordRef = {
    displayText: string;
    baseForm: string;
    reading: string;
    definitionIds: number[];
};
export type DialogInfo = {
    id: string;
    startTime: number;
    words: WordRef[][];
    isSongLyric: boolean;
};
export type WordOccurrence = {
    episodeName: string;
    time: number;
    text: DialogInfo;
    episodeId: string;
};
export type WordRankInfo = {
    id: number;
    rank: number;
    count: number;
    text: string;
};
export type JapaneseWord = {
    kanji?: string | null;
    reading?: string | null;
};
export type EnglishWord = {
    tags: string[];
    senses: string[];
};
export type PriorityInfo = {
    news: boolean;
    ichi: boolean;
    spec: boolean;
    freq: boolean;
    gai: boolean;
};
export type WordDefinition = {
    id: number;
    japanese: JapaneseWord[];
    english: EnglishWord[];
    priorities: PriorityInfo;
    globalRank?: number | null;
};
export type WordRank = {
    id: number;
    rank?: number | null;
};
export type WordOccurrences = {
    wordId: number;
    dialogIds: string[];
};
export type NoteInfo = {
    id: number;
    wordIds: number[];
    primaryWord: string;
    primaryWordReading: string;
    words: WordRef[];
};
export const {
    useActionsExecuteMutation,
    useActionsListQuery,
    useAlternateIdsMapQuery,
    useEngSubsIndexQuery,
    useEngSubsStylesOfQuery,
    useEngSubsActiveStylesForQuery,
    useEngSubsShowIdOfQuery,
    useEngSubsByStyleNameQuery,
    useEpisodeIndexQuery,
    useImageIndexQuery,
    useSubsByIdQuery,
    useUserGetTodoistTokenQuery,
    useUserPutTodoistTokenMutation,
    useWordsRanked2Query,
    useWordsSearchQuery,
    useWordsDefinitionQuery,
    useWordsEpisodeRankQuery,
    useWordsUnknownRanksQuery,
    useWordsEpisodeTotalQuery,
    useWordsOccurrencesQuery,
    useWordsNotesQuery,
    useWordsKnownQuery,
    useWordsWithReadingsKnownQuery
} = api;
